import AOEDATA from '../data/aoeData';

let aoeValue = 0;
let specialCoEfficiant = 0;
let moveCost = 0;
let knockbackCost = 0;
let totalDebuffCost = 0;
let targetStatusCost = 4;

const selfStatusCheckActionPoints = (one, two, three) => {
  let total = 0;

  if(one && one !== '') {
    if(two && two !== '') {
      total = 1;
      if(three && three !== '') {
        total = 2;
      }
    }
  };

  return total;
}

const movementCost = (move) => {
  const map = {
    0: 0,
    1: 0,
    2: 1,
    3: 1,
    4: 2,
    5: 2
  }

  return move <= 5 ? map[move] : 3;
}

const moveCoefficient = (budget) => {
  const costData = {
    55: 2,
    120: 3,
    210: 4,
    325: 5,
    465: 6,
    630: 7,
    820: 8,
    1035: 9,
    1275: 10  
  }

  return costData[budget];
}

const rangeMultiplier = (range) => {
  const rangeData = {
    0: 1,
    1: 1,
    2: 1.23,
    3: 1.4,
    4: 1.65,
    5: 1.8,
    6: 2
  }
  return rangeData[range];
}

const knockbackMap = {
  '55': 2,
  '120': 3,
  '210': 4,
  '325': 5,
  '465': 6,
  '630': 7,
  '820': 8,
  '1035': 9,
  '1275': 10
}

const utilityCost = ({statusSelf1, statusSelf2, statusSelf3, move, disengage, knockback, budget}) => {
  // Max SS Value = (statusSelf1(0 or 5) + statusSelf2(0 or 6) + statusSelf3(0 or 9))
  const MaxSSValue = ((statusSelf1 ? 6.6 : 0) + (statusSelf2 ? 6.6 : 0) + (statusSelf3 ? 6.6 :0));

  // Move Cost = (move + disengage) * 3.5
  moveCost = (move + disengage) * moveCoefficient(budget);

  // use budget to to determine multiplyer
  knockbackCost = knockback * knockbackMap[budget.toString()];

  // Total Utility Value (TUV) = Max SS Value + Move Cost + Range Cost + Knockback Cost
  const totalUtilityCost = MaxSSValue + moveCost + knockbackCost;

  return (totalUtilityCost * (totalUtilityCost +1) / 2);
}

const damageCost = ({aoe, aoeSize, dice, damage, range}) => {
  // Special co-efficiant (SO) = consumeStaus(.5) or 1
  const aoeMappedValue = AOEDATA[aoe].value;
  specialCoEfficiant = dice === -1 ? 0.5 : 1;

  aoeValue = aoeMappedValue * aoeSize;
  // Range Cost = (0 or (range * 3))
  const rangeCost = rangeMultiplier(range);
  // AoE Value = type * size / 2) + .5 (if type = lance) 
  if (aoe === 4) aoeValue = aoeValue + 0.5;

  // Damage cost = (MD * (MD + 1) / 2) * SO
  // damageCost = damage * rangeCost *aoeValue * dice 
  const maxDamage = damage * rangeCost * aoeValue * dice;

  return maxDamage * (maxDamage + 1) / 2;
}

const debuffCost = ({statusTarget1, statusTarget2, statusTarget3, dice }) => {
  // Max TS Value = (((statusTarget1(0 or 3.3) + statusTarget2(0 or 3.3) + statusTarget3(0 or 3.3)) + consumeState(3) or dice) * AoE Value )
  const TSBase = dice === -1 ? 3 : dice;
  const MaxTSValue = (((statusTarget1 ? targetStatusCost : 0) + (statusTarget2 ? targetStatusCost : 0) + (statusTarget3 ? targetStatusCost : 0)) * TSBase) * aoeValue;
  
  totalDebuffCost = (MaxTSValue * (MaxTSValue + 1) / 2) * specialCoEfficiant;
  return totalDebuffCost;
}

const actionPoints = ({damage, aoe, dice, statusSelf1, statusSelf2, statusSelf3, move}) => {
 const moveCheck = movementCost(move);
 const knockbackCheck = knockbackCost ? 1 : 0;
 const damageCheck = damage ? 1 : 0;
 const statusCheck = selfStatusCheckActionPoints(statusSelf1, statusSelf2, statusSelf3);
 const aoeCheck = aoe !== 0 ? 1 : 0;
 const aggresiveCheck = dice ? 1 : 0;
//  const debuffCheck = totalDebuffCost > 1 ? 1 : 0;
  const debuffCheck = damageCheck > 0 ? 0 : totalDebuffCost > 1 ? 1 : 0;

 return (moveCheck + aoeCheck + statusCheck + knockbackCheck + aggresiveCheck + debuffCheck + damageCheck);
}

const calculateTotalCost = (protocol) => {
  // utiltityCost + damageCost + debuffCost
  // console.log('uc: ',utilityCost(protocol),'dc: ', damageCost(protocol), 'dbc: ', debuffCost(protocol));
  return {
    cost:Math.round(utilityCost(protocol) + damageCost(protocol) + debuffCost(protocol)),
    actionPoints: actionPoints(protocol)
  };
}

export default calculateTotalCost;