import React from "react";

import './advertWrap.scss';

const ads = [
  'A', 'FIB', 'NLS', 'R-Corp', 'T-ad'
];

const generateAd = () => {
  return ads[Math.floor(Math.random() * (ads.length - 1))]
}

const AdvertWrap = () => {
  return (
    <div className="advertWrap flex">
      <img src={`/images/adverts/${generateAd()}-ad.jpg`} alt="Advert" />
    </div>
  )
}

export default AdvertWrap;